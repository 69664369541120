import { createApp } from 'vue'
import App from './App.vue'
import { router } from './router/router.js'
import axios from "axios";
import VueGtag from "vue-gtag";

// createApp(App).mount('#app')
const app = createApp(App)
app.use(VueGtag, {
  config: { id: "G-6E5JFTF4T7" }
})
app.use(router)  // 라우터 사용
app.config.globalProperties.$axios = axios; 
app.mount('#app')
